import React from 'react';
import Banner from './banner';
import Aboutus from './aboutus'
import Process from './process'
import Extra from './extra'
import Team from './team'
import TestimonialBlock from './testimonial-block'
import ClientBlock from './client-block'

const About = () => {
  return (
    <div>
      <Banner />
      <Aboutus />
      {/* <Process/> */}
      <Extra/>
      <Team/>
      <TestimonialBlock/>
      <ClientBlock/>
    </div>
  );
};

export default About;
