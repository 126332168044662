import React from 'react';
import { Link } from 'react-router-dom'


const team = () => {
    return (
        <section>
            <div className="container">
                <div className="section-title mb-1-9 mb-md-6 text-center wow fadeInUp" data-wow-delay="200ms">
                    <span>Our Team</span>
                    <h2 className="mb-0 h1 mt-2">Our Motivated Team</h2>
                </div>
                <div className="row g-xl-5 mt-n1-9">
                    
                    <div className="col-sm-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="300ms">
                        <div className="card card-style-05 border-0">
                            <div className="card-image text-center">
                                <img src="/img/team/Mr.-Akash-Marotkar-Founder-of-Sunsathi-Solar-Inverter.webp" alt="Mr.-Akash-Marotkar-Founder-of-Sunsathi-Solar-Inverter"/>
                                {/* <div className="hover-area">
                                    <div className="profile-footer">
                                        <ul className="team-social">
                                            <li><a href="#!"><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li><a href="#!"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#!"><i className="fab fa-linkedin-in"></i></a></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                            <div className="card-body text-center p-0 pt-4">
                                <h3 className="h5"><a href="#">Akash Marotkar</a></h3>
                                <span className="text-primary">Founder of Sunsathi Solar Inverter</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="200ms">
                        <div className="card card-style-05 border-0">
                            <div className="card-image text-center">
                                <img src="/img/team/Mr.-Suraj-Marotkar-Co-Founder-of-Sunsathi-Solar-Inverter.webp" alt="Mr.-Suraj-Marotkar-Co-Founder-of-Sunsathi-Solar-Inverter"/>
                                {/* <div className="hover-area">
                                    <div className="profile-footer">
                                        <ul className="team-social">
                                            <li><a href="#!"><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li><a href="#!"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#!"><i className="fab fa-linkedin-in"></i></a></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                            <div className="card-body text-center p-0 pt-4">
                                <h3 className="h5"><a href="#">Suraj Marotkar</a></h3>
                                <span className="text-primary">Co-Founder of Sunsathi Solar Inverter</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="200ms">
                        <div className="card card-style-05 border-0">
                            <div className="card-image text-center">
                                <img src="/img/team/Mrs.-Manisha-Marotkar-Co-Founder.webp" alt="Mrs.-Manisha-Marotkar-Co-Founder"/>
                                {/* <div className="hover-area">
                                    <div className="profile-footer">
                                        <ul className="team-social">
                                            <li><a href="#!"><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li><a href="#!"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#!"><i className="fab fa-linkedin-in"></i></a></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                            <div className="card-body text-center p-0 pt-4">
                                <h3 className="h5"><a href="#">Mrs. Manisha Marotkar</a></h3>
                                <span className="text-primary">Co-Founder of Sunsathi Solar Inverter</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
}

export default team;