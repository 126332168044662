import React from 'react';


const info = () => {
    return (
        <section className="p-0 bg-light">
            <div className="container-fuild">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14911.98620096355!2d77.7559356!3d20.8721893!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd6a55eea32eafb%3A0xbf86b740a6568e79!2sSunsathi%20Solar%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1723289913486!5m2!1sen!2sin"  className="contact-map" id="gmap_canvas"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>
    );
}

export default info;