import React from 'react';


const banner = () => {
    return (
        <section className="page-title-section bg-img cover-background dark-overlay top-position" data-overlay-dark="6" style={{ backgroundImage: 'url(img/banner/About-Us-Section-1-Banner-1920.webp)' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="display-3">Frequently Asked Questions (FAQs)</h1>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="#!">FAQs</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="line-animated">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="p-1-9 p-sm-2-3 bg-white bottom-0 left d-inline-block position-absolute z-index-3"></div>
            <div className="position-absolute shape-1 d-none d-sm-block"></div>
            <div className="px-6 px-sm-9 py-3 py-sm-4 bg-white bottom-0 right d-inline-block position-absolute z-index-3"></div>
        </section>
    );
}

export default banner;