import React from 'react';


const Header = () => {
    return (
            <header className="header-style3">
                <div className="navbar-default border-bottom border-color-light-white">
                    <div className="top-search bg-primary">
                        <div className="container-fluid px-lg-1-6 px-xl-2-5 px-xxl-2-9">
                            <form className="search-form" action="/product" method="GET" acceptCharset="utf-8">
                                <div className="input-group">
                                    <span className="input-group-addon cursor-pointer">
                                        <button className="search-form_submit fas fa-search text-white" type="submit"></button>
                                    </span>
                                    <input type="text" className="search-form_input form-control" name="s" autoComplete="off" placeholder="Type & hit enter..."/>
                                    <span className="input-group-addon close-search mt-1"><i className="fas fa-times"></i></span>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="container-fluid px-lg-1-6 px-xl-2-5 px-xxl-2-9">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-12">
                                <div className="menu_area alt-font">
                                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                                        <div className="navbar-header navbar-header-custom">
                                            <a href="/" className="navbar-brand logochange"><img id="logo" src="/img/logos/logo.png" alt="logo" /></a>
                                        </div>

                                        <div className="navbar-toggler"></div>

                                        <ul className="navbar-nav ms-auto" id="nav" style={{ display: 'none' }}>
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>
                                                <a href="/about">About Us</a>
                                            </li>
                                            <li>
                                                <a href="/product">Products</a>
                                            </li>
                                            <li>
                                                <a href="/datasheet">Datasheets</a>
                                            </li>
                                            {/* <li>
                                                <a href="/certificate">Certificates</a>
                                            </li>
                                            <li>
                                                <a href="/gallery">Gallery</a>
                                            </li> */}
                                            <li>
                                                <a href="/blogs">Blogs</a>
                                            </li>
                                            <li>
                                                <a href="/contact">Contact Us</a>
                                            </li>
                                        </ul>

                                        <div className="attr-nav align-items-xl-center ms-xl-auto main-font">
                                            <ul>
                                                <li className="search"><a href="/product"><i className="fas fa-search"></i></a></li>
                                                <li className="d-none d-xl-inline-block"><a href="/contact" className="btn-style1 medium text-white">GET a QUOTE</a></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </header>
    );
}

export default Header;