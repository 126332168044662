import React from 'react';
import { Link } from 'react-router-dom'


const index = () => {
    return (
        <section>
            <div className="container">
                <div className="row mt-n2-9">
                    <div className="col-lg-4 pe-xl-5 mt-2-9 order-2 order-lg-1">
                        <div className="sidebar">
                            {/* <div className="widget mb-1-9 wow fadeIn" data-wow-delay="200ms">
                                <div className="widget-title">
                                    <h5 className="mb-0">Search</h5>
                                </div>
                                <div className="widget-content">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search here..."/>
                                        <div className="input-group-append">
                                            <button className="btn-style1" type="button"><i className="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="widget mb-1-9 wow fadeIn" data-wow-delay="200ms">
                                <div className="widget-title">
                                    <h5 className="mb-0">Recent Blogs</h5>
                                </div>
                                <div className="widget-content">
                                <div className="d-flex mb-4">
                                        <div className="flex-shrink-0">
                                            <img src="/blog-images/The-Future-of-Solar-Inverters-Innovations-and-Trends-Shaping-Renewable-Energy.webp" style={{width:'85px'}} alt="..."/>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="mb-2 h6"><a href="/blog/the-future-of-solar-inverters">The Future of Solar Inverters</a></h4>
                                            <span className="text-primary small">August 01, 2024</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-4">
                                        <div className="flex-shrink-0">
                                            <img src="/blog-images/How-Solar-Inverters-Contribute-to-Reducing-Your-Carbon-Footprint.webp"  style={{width:'85px'}} alt="..."/>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="mb-2 h6"><a href="/blog/how-solar-inverters-contribute-to-reducing-your-carbon-footprint">How Solar Inverters Contribute to Reducing Your Carbon Footprint</a></h4>
                                            <span className="text-primary small">August 02, 2024</span>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <img src="/blog-images/How-to-Choose-Right-Solar-Inverter-for-Your-Home.webp"  style={{width:'85px'}} alt="..."/>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="mb-2 h6"><a href="/blog/how-to-choose-the-right-solar-inverter-for-your-home">How to Choose the Right Solar Inverter for your Home</a></h4>
                                            <span className="text-primary small">August 03, 2024</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="widget mb-1-9 wow fadeIn" data-wow-delay="200ms">
                                <div className="widget-title">
                                    <h5 className="mb-0">Products</h5>
                                </div>
                                <div className="widget-content">
                                    <ul className="list-unstyled menu mb-0 ps-0">
                                        <li><a href="/product">Single Phase - 1MPPT 2.4 to 5.4 KW</a></li>
                                        <li><a href="/product">Single Phase - 2MPPT 5.2 to 6.4 kW</a></li>
                                        <li><a href="/product">Three Phase Inverter - 2MPPT</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bg-img p-1-9 cover-background contact-widget wow fadeIn" data-wow-delay="200ms" data-overlay-dark="5" style={{ backgroundImage: "url('/img/content/sidebar-img1.webp')" }}>
                                <div className="z-index-3 position-relative">
                                    <h3 className="text-white mb-1-9">Ready To Get Expert Our Services</h3>
                                    <a href="/contact" className="btn-style1 small">Contact Us</a>
                                </div>
                                <span className="position-absolute right bottom-0 p-3 bg-white z-index-3"></span>
                                <span className="shape-2"></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mt-2-9 order-1 order-lg-2">
                        <div>
                            <div className="row g-xl-5 mt-n2-2">
                                <div className="col-md-6 mt-2-2 wow fadeIn" data-wow-delay="200ms">
                                    <article className="card card-style-04 border-color-light-black h-100">
                                        <div className="blog-img position-relative">
                                            <img src="img/datasheets/datasheet-img.webp" alt="..."/>
                                            <div className="card-list start-50 translate-middle-x">
                                                <span className="display-31"><i className="ti-signal me-1 text-white"></i> 2.4KW - 6.4KW</span>
                                            </div>
                                        </div>
                                        <div className="card-body p-1-9 pt-2-3">
                                            <h3 className="h4 mb-1-6 border-bottom border-color-light-black pb-1-6 text-center"><a href="datasheets/Datasheet_Single_Phase.pdf" target='_blank' >Single Phase<br/>(Single / Dual MPPT)</a></h3>
                                            <a href="datasheets/Datasheet_Single_Phase.pdf" target='_blank' className="text-secondary text-primary-hover fw-bold display-28 text-center">Download Broucher</a>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 mt-2-2 wow fadeIn" data-wow-delay="200ms">
                                    <article className="card card-style-04 border-color-light-black h-100">
                                        <div className="blog-img position-relative">
                                            <img src="img/datasheets/datasheet-img.webp" alt="..."/>
                                            <div className="card-list start-50 translate-middle-x">
                                                <span className="display-31"><i className="ti-signal me-1 text-white"></i> 5KW - 30KW</span>
                                            </div>
                                        </div>
                                        <div className="card-body p-1-9 pt-2-3">
                                            <h3 className="h4 mb-1-6 border-bottom border-color-light-black pb-1-6 text-center"><a href="datasheets/Datasheet_Three_Phase.pdf" target='_blank' >Three Phase<br/>(Multi MPPT)</a></h3>
                                            <a href="datasheets/Datasheet_Three_Phase.pdf" target='_blank' className="text-secondary text-primary-hover fw-bold display-28 text-center">Download Broucher</a>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default index;