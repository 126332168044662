import React, { useEffect } from 'react';
import $ from 'jquery';

const Extra = () => {
    
    useEffect(() => {
        // jQuery code to handle mouseenter event and background change
        $('.vision-wrapper').on('mouseenter', function () {
            var bg = $(this).attr("data"); // Get the background image of the hovered element
            $('.vision-changebg').animate({ opacity: 0.9 }, 50, function () {
                document.getElementById("vision-changebg").style.background = "url("+bg+")";
            });
            
            $('.vision-changebg').delay(50).animate({ opacity: 0.9 }, 50);
        });

        // Cleanup function to remove event listeners
        return () => {
            $('.vision-wrapper').off('mouseenter');
        };
    }, []);

    return (
        <section id="vision-changebg" className="vision-changebg owl-carousel owl-theme py-0 bg-img cover-background" data-overlay-dark="0" style={{ backgroundImage: "url('/img/banner/About-Us-Section-4-Cutting-Edge-Technology-1920x650.webp')" }}>
            <div className="vision-wrapper bg-img cover-background" data-overlay-dark="2" data="/img/banner/About-Us-Section-4-Cutting-Edge-Technology-1920x650.webp" >
                <div className="vision-content">
                    <h4 className="text-white mb-3">Cutting-Edge Technology</h4>
                    {/* <p className="mb-0 text-white">The modern world is in a continuous movement and people everywhere are looking.
                    </p> */}
                </div>
            </div>
            <div className="vision-wrapper bg-img cover-background" data-overlay-dark="2" data="/img/banner/About-Us-Section-4-Quality-Assurance-1920x650.webp" >
                <div className="vision-content">
                    <h4 className="text-white mb-3">Quality Assurance</h4>
                    {/* <p className="mb-0 text-white">The modern world is in a continuous movement and people everywhere are looking.
                    </p> */}
                </div>
            </div>
            <div className="vision-wrapper bg-img cover-background" data-overlay-dark="2" data="/img/banner/About-Us-Section-4-Customer-Focus-1920x650.webp">
                <div className="vision-content">
                    <h4 className="text-white mb-3">Customer Focus</h4>
                    {/* <p className="mb-0 text-white">The modern world is in a continuous movement and people everywhere are looking.
                    </p> */}
                </div>
            </div>
            <div className="vision-wrapper bg-img cover-background" data-overlay-dark="2" data="/img/banner/About-Us-Section-4-Efficient-Manufacturing-1920x650.webp">
                <div className="vision-content">
                    <h4 className="text-white mb-3">Efficient Manufacturing</h4>
                    {/* <p className="mb-0 text-white">The modern world is in a continuous movement and people everywhere are looking.
                    </p> */}
                </div>
            </div>
        </section>
    );
}

export default Extra;