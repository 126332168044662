import React from 'react';
import { Link } from 'react-router-dom'


const blog_block = () => {
    return (
        <section className="blog-style-01 bg-light pt-24">
            <div className="container">
                <div className="section-title-02 mb-1-9 text-center wow fadeInUp" data-wow-delay="200ms">
                    <span className="line-one center"></span>
                    <span className="line-two center"></span>
                    <h2 className="display-5 font-weight-800 mb-0">Our Blog</h2>
                </div>
                <div className="row mt-n1-9">
                    <div className="col-md-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="200ms">
                        <article className="bg-img cover-background blog-img height-350" style={{ backgroundImage: "url('/blog-images/The-Future-of-Solar-Inverters-Innovations-and-Trends-Shaping-Renewable-Energy.webp')" }}>
                            <div className="overlay">
                                <div className="overlay-inner">
                                    <span className="text-white">August 01, 2024</span>
                                    <h3 className="mb-0 h5 mt-2"><a href="/blog/the-future-of-solar-inverters" className="text-white">The Future of Solar Inverters</a></h3>
                                </div>
                                <div className="blog-btn">
                                    <a href="/blog/the-future-of-solar-inverters" className="text-white">Read More <i className="ti-arrow-right display-30 align-middle ms-2"></i></a>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="300ms">
                        <article className="bg-img cover-background blog-img height-350" style={{ backgroundImage: "url('/blog-images/How-Solar-Inverters-Contribute-to-Reducing-Your-Carbon-Footprint.webp')" }}>
                            <div className="overlay">
                                <div className="overlay-inner">
                                    <span className="text-white">August 02, 2024</span>
                                    <h3 className="mb-0 h5 mt-2"><a href="/blog/how-solar-inverters-contribute-to-reducing-your-carbon-footprint" className="text-white">How Solar Inverters Contribute to Reducing Your Carbon Footprint</a></h3>
                                </div>
                                <div className="blog-btn">
                                    <a href="/blog/how-solar-inverters-contribute-to-reducing-your-carbon-footprint" className="text-white">Read More <i className="ti-arrow-right display-30 align-middle ms-2"></i></a>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="400ms">
                        <article className="bg-img cover-background blog-img height-350" style={{ backgroundImage: "url('/blog-images/How-to-Choose-Right-Solar-Inverter-for-Your-Home.webp')" }}>
                            <div className="overlay">
                                <div className="overlay-inner">
                                    <span className="text-white">August 03, 2024</span>
                                    <h3 className="mb-0 h5 mt-2"><a href="/blog/how-to-choose-the-right-solar-inverter-for-your-home" className="text-white">How to Choose the Right Solar Inverter for your Home</a></h3>
                                </div>
                                <div className="blog-btn">
                                    <a href="/blog/how-to-choose-the-right-solar-inverter-for-your-home" className="text-white">Read More <i className="ti-arrow-right display-30 align-middle ms-2"></i></a>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default blog_block;