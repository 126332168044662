import React from 'react';
import { Link } from 'react-router-dom'


const service_block = () => {
    return (
        <section className="service-style-01 pt-0">
            <div className="container">
                <div className="section-title-02 mb-1-9 text-center wow fadeInUp" data-wow-delay="200ms">
                    <span className="line-one center"></span>
                    <span className="line-two center"></span>
                    <h2 className="display-5 font-weight-800 mb-0">Our Best Services</h2>
                </div>
                <div className="row mt-n1-9">
                    <div className="col-md-6 col-xl-3 mt-1-9 wow fadeIn" data-wow-delay="200ms">
                        <div className="bg-img cover-background height-350 text-center service-img" style={{ backgroundImage: "url('img/service/High-Efficiency-575x365.webp')" }}>
                            <div className="overlay">
                                <h3 className="mb-0 h5"><a href="#" className="text-white">High Efficiency</a></h3>
                                <div className="service-btn">
                                    {/* <a href="solar-solutions.html" className="text-white">Read More <i className="ti-arrow-right display-30"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 mt-1-9 wow fadeIn" data-wow-delay="300ms">
                        <div className="bg-img cover-background height-350 text-center service-img" style={{ backgroundImage: "url('img/service/Reliable-Performance-575x365.webp')" }}>
                            <div className="overlay">
                                <h3 className="mb-0 h5"><a href="#" className="text-white">Reliable Performance</a></h3>
                                <div className="service-btn">
                                    {/* <a href="wind-turbine.html" className="text-white">Read More <i className="ti-arrow-right display-30"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 mt-1-9 wow fadeIn" data-wow-delay="400ms">
                        <div className="bg-img cover-background height-350 text-center service-img" style={{ backgroundImage: "url('img/service/Smart-Monitoring-575x365.webp')" }}>
                            <div className="overlay">
                                <h3 className="mb-0 h5"><a href="#" className="text-white">Smart Monitoring</a></h3>
                                <div className="service-btn">
                                    {/* <a href="renewable-energy.html" className="text-white">Read More <i className="ti-arrow-right display-30"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 mt-1-9 wow fadeIn" data-wow-delay="500ms">
                        <div className="bg-img cover-background height-350 text-center service-img" style={{ backgroundImage: "url('img/service/Seamless-Integration-575x365.webp')" }}>
                            <div className="overlay">
                                <h3 className="mb-0 h5"><a href="#" className="text-white">Seamless Integration</a></h3>
                                <div className="service-btn">
                                    {/* <a href="energy-transition.html" className="text-white">Read More <i className="ti-arrow-right display-30"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default service_block;