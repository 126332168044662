import React from 'react';
import { Link } from 'react-router-dom'


const client_block = () => {
    return (
        <section>
            <div className="container">
                <div className="section-title mb-1-9 mb-md-6 text-center wow fadeInUp" data-wow-delay="200ms">
                    <span>Our Partners</span>
                    <h2 className="mb-0 h1 mt-2">Meet Our Clients</h2>
                </div>
                <div className="row g-0 wow fadeIn ">
                    <div className="col-md-4 col-lg-2 text-center client-style-01 borders-end border-md-end-0 border-color-light-black">
                        <img src="img/clients/1.png" alt="..."/>
                    </div>
                    <div className="col-md-4 col-lg-2 text-center client-style-01 borders-end border-md-end-0 border-color-light-black">
                        <img src="img/clients/2.png" alt="..."/>
                    </div>
                    <div className="col-md-4 col-lg-2 text-center client-style-01 borders-end border-lg-end-0 border-color-light-black">
                        <img src="img/clients/3.png" alt="..."/>
                    </div>
                    <div className="col-md-4 col-lg-2 text-center client-style-01 borders-end border-md-end-0 border-color-light-black">
                        <img src="img/clients/4.png" alt="..."/>
                    </div>
                    <div className="col-md-4 col-lg-2 text-center client-style-01 borders-end border-md-end-0 border-color-light-black">
                        <img src="img/clients/5.png" alt="..."/>
                    </div>
                    <div className="col-md-4 col-lg-2 text-center client-style-01 border-end border-color-light-black">
                        <img src="img/clients/6.png" alt="..."/>
                    </div>
                    <div className="col-md-4 col-lg-2 text-center client-style-01 border-end border-color-light-black">
                        <img src="img/clients/7.png" alt="..."/>
                    </div>
                    <div className="col-md-4 col-lg-2 text-center client-style-01 border-end border-color-light-black">
                        <img src="img/clients/8.png" alt="..."/>
                    </div>
                    <div className="col-md-4 col-lg-2 text-center client-style-01 border-end border-color-light-black">
                        <img src="img/clients/9.png" alt="..."/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default client_block;