import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Footer from './components/layout/footer';
import Header from './components/layout/header'
import Home from './components/home/home';
import About from './components/about/about';
import Product from './components/product/product';
import Certificate from './components/certificate/certificate';
import Datasheet from './components/datasheet/datasheet';
import Gallery from './components/gallery/gallery';
import Contact from './components/contact/contact';
import Blog from './components/blog/blog';
import Faq from './components/faq/faq';
import Terms from './components/terms/terms';
import Policy from './components/policy/policy';
import BlogPost from './components/blog/BlogPost';

function App() {
  return (
      <div className="main-wrapper">
          <BrowserRouter>
          <Header/>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/product" element={<Product/>} />
            <Route path="/datasheet" element={<Datasheet/>} />
            <Route path="/certificate" element={<Certificate/>} />
            <Route path="/gallery" element={<Gallery/>} />
            <Route path="/blogs" element={<Blog/>} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/faqs" element={<Faq/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/privacy-policy" element={<Policy/>} />
            <Route path="/terms-conditions" element={<Terms/>} />
          </Routes>
          <Footer/>
          </BrowserRouter>

          <a href="#!" className="scroll-to-top"><i className="fas fa-angle-up" aria-hidden="true"></i> </a>
      </div>
  );
}

export default App;
