import React from 'react';
import { Link } from 'react-router-dom'


const Aboutus = () => {
    return (
        <section className="pb-0 overflow-visible">
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-6 mb-5 mb-lg-0 wow fadeIn" data-wow-delay="200ms">
                        <div className="position-relative about-style-02 ps-sm-10 pt-sm-12 pb-sm-6">
                            <div className="position-relative">
                                <img src="/img/content/About-Us-Section-2-480x450.png" className="z-index-1 position-relative" alt="..."/>
                                <span className="about-shape1"></span>
                                <span className="about-shape2"></span>
                            </div>
                            <img src="/img/content/About-Us-Section-2-270x300.png" alt="..." className="position-absolute top-0 left z-index-2 d-none d-sm-block"/>
                            <div className="bg-white shadow p-1-9 text-center position-absolute bottom-0 right-5 z-index-2">
                                {/* <h3 className="h1 text-primary font-weight-800"><span className="countup">7</span>+</h3>
                                <p className="mb-0">Years Experience</p> */}
                                <img src='/img/logos/MADE-IN-BHARAT-LOGO.png'/>
                            </div>
                            <div className="d-inline-block p-6 bg-secondary top-10 opacity3 z-index-0 right-20 position-absolute"></div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="400ms">
                        <div>
                            <div className="section-title">
                                <span>About Us</span>
                            </div>
                            <h2 className="h1 mb-4 mt-2 z-index-2 position-relative">7+ Years of Experience in Solar Industry</h2>
                            <p className="mb-1-9">
                            Welcome to Sunsathi Solar Pvt. Ltd., the leading solar inverter manufacturer
                            in India. At Sunsathi Solar, we are dedicated to revolutionizing the
                            renewable energy sector by providing top-of-the-line solar inverters
                            designed for maximum efficiency, reliability, and sustainability. Our
                            commitment to innovation and quality has made us a trusted name in the
                            industry, catering to a diverse range of clients from residential to
                            commercial sectors.
                            </p>
                            <div className="row">
                                <div className="col-lg-6 mb-4 mb-lg-0">
                                    <div className="d-flex align-items-center bg-light p-3 mb-4">
                                        <i className="fa-solid fa-circle-check display-20 text-secondary"></i>
                                        <span className="ms-3 text-dark fw-bold">Commitment to Innovation</span>
                                    </div>
                                    <div className="d-flex align-items-center bg-light p-3">
                                        <i className="fa-solid fa-circle-check display-20 text-secondary"></i>
                                        <span className="ms-3 text-dark fw-bold">Top-Quality Products</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex align-items-center bg-light p-3 mb-4">
                                        <i className="fa-solid fa-circle-check display-20 text-secondary"></i>
                                        <span className="ms-3 text-dark fw-bold">Trusted Industry Name</span>
                                    </div>
                                    <div className="d-flex align-items-center bg-light p-3">
                                        <i className="fa-solid fa-circle-check display-20 text-secondary"></i>
                                        <span className="ms-3 text-dark fw-bold">Diverse Clientele</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Aboutus;