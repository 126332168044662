import React from 'react';
import Banner from './banner';
import AboutBlock from '../common/about-block'
import ServiceBlock from '../common/service-block'
import ProcessBlock from '../common/process-block'
import ClientBlock from '../common/client-block'
import PortfolioBlock from '../common/portfolio-block'
import TestimonialBlock from '../common/testimonial-block'
import CounterBlock from '../common/counter-block'
import BlogBlock from '../common/blog-block'

const Home = () => {
  return (
    <div>
      <Banner />
      <AboutBlock />
      <ServiceBlock/>
      <ProcessBlock/>
      {/* <ClientBlock/> */}
      {/* <PortfolioBlock/> */}
      {/* <TestimonialBlock/> */}
      {/* <CounterBlock/> */}
      <BlogBlock/>
    </div>
  );
};

export default Home;
