import React, { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha"

const Form = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        message: '',
      });
    const [responseMessage, setResponseMessage] = useState('');
    const [error, setError] = useState('');

    const [recaptchaToken, setRecaptchaToken] = useState('');
    const recaptchaRef = useRef(null);
    
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleRecaptcha = (token) => {
        setRecaptchaToken(token);
    };
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!recaptchaToken) {
            setError('Please complete the reCAPTCHA.');
            return;
        }

        const jsonData = JSON.stringify(formData);
        console.log(jsonData);
        try {
          const response = await fetch('https://secure.sunsathisolar.com/api/contact', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: jsonData,
          });
    
          const data = await response.json();
          console.log(data);
            if (data.errors) {
                setError(data.errors);
                console.error('Error:', data);
            } else {
                setError("");
                console.log('Form submitted successfully:', data);
                setResponseMessage(data.message);
                setFormData({ name: '', email: '', message: '', contact: '' }); // Reset form
                recaptchaRef.current.reset(); // Reset reCAPTCHA
                setRecaptchaToken(''); // Clear recaptcha token
            }
        } catch (err) {
          setError('Network error');
        }
    };

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="pe-lg-1-9">
                            <div className="card card-style-03 mb-1-9">
                                <div className="card-body p-1-6 p-sm-1-9">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="ti-location-pin text-secondary z-index-9 display-8 position-relative"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="h5 text-white">Location</h4>
                                            <span className="text-white opacity7">Plot No 50, Govinda Industrial Park, Infront of Lally Lawn, Walad, MIDC, Amravati, Maharashtra 444607</span>
                                        </div>
                                    </div>
                                    <i className="ti-location-pin bg-icon"></i>
                                </div>
                            </div>
                            <div className="card card-style-03 mb-1-9">
                                <div className="card-body p-1-6 p-sm-1-9">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="ti-mobile text-secondary z-index-9 display-8 position-relative"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="h5 text-white">Phone Number</h4>
                                            <span className="d-block text-white opacity7">(+91) 8484838985</span>
                                            {/* <span className="text-white opacity7">(+1) 234-567-9874</span> */}
                                        </div>
                                    </div>
                                    <i className="ti-mobile bg-icon"></i>
                                </div>
                            </div>
                            <div className="card card-style-03">
                                <div className="card-body p-1-6 p-sm-1-9">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="ti-email text-secondary z-index-9 display-8 position-relative"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="h5 text-white">Email Address</h4>
                                            <span className="d-block text-white opacity7">sales@sunsathisolar.com</span>
                                            {/* <span className="text-white opacity7">info@yourdomain.com</span> */}
                                        </div>
                                    </div>
                                    <i className="ti-email bg-icon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <h2 className="mb-4">Contact Us</h2>
                        <form className="contact" onSubmit={handleSubmit}>
                            <div className="quform-elements">
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="quform-element form-group">
                                            <div className="quform-input">
                                                <input className="form-control" id="name" type="text" name="name" placeholder="Your name" value={formData.name} onChange={handleChange} required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="quform-element form-group">
                                            <div className="quform-input">
                                                <input className="form-control" id="email" type="text" name="email" placeholder="Your email id" value={formData.email} onChange={handleChange} required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="quform-element form-group">
                                            <div className="quform-input">
                                                <input className="form-control" id="contact" type="text" name="contact" placeholder="Your contact number" value={formData.contact} onChange={handleChange} required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="quform-element form-group">
                                            <div className="quform-input">
                                                <textarea className="form-control" id="message" name="message" rows="3" placeholder="Tell us a few words" onChange={handleChange} value={formData.message} required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="quform-element">
                                            <div className="form-group">
                                            <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleRecaptcha}
                                            ref={recaptchaRef} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="quform-submit-inner">
                                            <button className="btn-style1" type="submit">Send Message</button>
                                        </div>
                                        {responseMessage && <p style={{color:'green'}}>{responseMessage}</p>}{error && <p style={{color:'red'}}>{error}</p>}
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Form;