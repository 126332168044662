import React from 'react';
import { Link } from 'react-router-dom'


const index = () => {
    return (
        <section>
            <div className="container">
                <div className="row mt-n2-9">
                    <div className="col-lg-4 pe-xl-5 mt-2-9 order-2 order-lg-1">
                        <div className="sidebar">
                            <div className="widget mb-1-9 wow fadeIn" data-wow-delay="200ms">
                                <div className="widget-title">
                                    <h5 className="mb-0">Search</h5>
                                </div>
                                <div className="widget-content">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search here..."/>
                                        <div className="input-group-append">
                                            <button className="btn-style1" type="button"><i className="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="widget mb-1-9 wow fadeIn" data-wow-delay="200ms">
                                <div className="widget-title">
                                    <h5 className="mb-0">Recent Posts</h5>
                                </div>
                                <div className="widget-content">
                                    <div className="d-flex mb-4">
                                        <div className="flex-shrink-0">
                                            <img src="img/blog/blog-thumb-01.jpg" alt="..."/>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="mb-2 h6"><a href="#!">Tech review resources you will ever need</a></h4>
                                            <span className="text-primary small">14 Apr 2023</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-4">
                                        <div className="flex-shrink-0">
                                            <img src="img/blog/blog-thumb-02.jpg" alt="..."/>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="mb-2 h6"><a href="#!">The height of world's biggest wind turbine</a></h4>
                                            <span className="text-primary small">12 Apr 2023</span>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <img src="img/blog/blog-thumb-03.jpg" alt="..."/>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="mb-2 h6"><a href="#!">How is electricity produced from solar panels?</a></h4>
                                            <span className="text-primary small">10 Apr 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="widget mb-1-9 wow fadeIn" data-wow-delay="200ms">
                                <div className="widget-title">
                                    <h5 className="mb-0">Categories</h5>
                                </div>
                                <div className="widget-content">
                                    <ul className="list-unstyled menu mb-0 ps-0">
                                        <li><a href="#!">Renewable Energy (3)</a></li>
                                        <li><a href="#!">Wind Trubine (4)</a></li>
                                        <li><a href="#!">Repair & Maintenance (1)</a></li>
                                        <li><a href="#!">Solar Panel (1)</a></li>
                                        <li><a href="#!">Wind Mill (3)</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="widget mb-1-9 wow fadeIn" data-wow-delay="200ms">
                                <div className="widget-title">
                                    <h5 className="mb-0">Tags</h5>
                                </div>
                                <div className="widget-content">
                                    <div className="blog-tags mt-n2">
                                        <a href="#!">Turbines</a>
                                        <a href="#!">Solar</a>
                                        <a href="#!">Battery</a>
                                        <a href="#!">Fossil</a>
                                        <a href="#!">Plants</a>
                                        <a href="#!">Hydro</a>
                                        <a href="#!">Infectious</a>
                                    </div>
                                </div>
                            </div>
                            <div className="widget mb-1-9 wow fadeIn" data-wow-delay="200ms">
                                <div className="widget-title">
                                    <h5 className="mb-0">Follow Us</h5>
                                </div>
                                <div className="widget-content">
                                    <ul className="social-icon-style2 ps-0">
                                        <li className="me-1"><a href="#!"><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="me-1"><a href="#!"><i className="fab fa-twitter"></i></a></li>
                                        <li className="me-1"><a href="#!"><i className="fab fa-instagram"></i></a></li>
                                        <li className="me-0"><a href="#!"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bg-img p-1-9 cover-background contact-widget wow fadeIn" data-wow-delay="200ms" data-overlay-dark="5" data-background="img/content/sidebar-img1.jpg">
                                <div className="z-index-3 position-relative">
                                    <h3 className="text-white mb-1-9">Ready To Get Expert Our Services</h3>
                                    <a href="contact.html" className="btn-style1 small">Contact Us</a>
                                </div>
                                <span className="position-absolute right bottom-0 p-3 bg-white z-index-3"></span>
                                <span className="shape-2"></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mt-2-9 order-1 order-lg-2">
                        <div>
                            <div className="row g-xl-5 mt-n2-2">
                                <div className="col-md-6 mt-2-2 wow fadeIn" data-wow-delay="200ms">
                                    <article className="card card-style-04 border-color-light-black h-100">
                                        <div className="blog-img position-relative">
                                            <img src="img/blog/blog-01.jpg" alt="..."/>
                                            <div className="card-list start-50 translate-middle-x">
                                                <span className="display-31"><i className="ti-calendar me-1 text-white"></i> Apr 11, 2023</span>
                                            </div>
                                        </div>
                                        <div className="card-body p-1-9 pt-2-3">
                                            <h3 className="h4 mb-1-6 border-bottom border-color-light-black pb-1-6"><a href="blog-details.html" >All you need to know about solar energy.</a></h3>
                                            <a href="blog-details.html" className="text-secondary text-primary-hover fw-bold display-28">Read More</a>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 mt-2-2 wow fadeIn" data-wow-delay="200ms">
                                    <article className="card card-style-04 border-color-light-black h-100">
                                        <div className="blog-img position-relative">
                                            <img src="img/blog/blog-02.jpg" alt="..."/>
                                            <div className="card-list start-50 translate-middle-x">
                                                <span className="display-31"><i className="ti-calendar me-1 text-white"></i> Apr 05, 2023</span>
                                            </div>
                                        </div>
                                        <div className="card-body p-1-9 pt-2-3">
                                            <h3 className="h4 mb-1-6 border-bottom border-color-light-black pb-1-6"><a href="blog-details.html" >Never underestimate the influence of energy.</a></h3>
                                            <a href="blog-details.html" className="text-secondary text-primary-hover fw-bold display-28">Read More</a>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 mt-2-2 wow fadeIn" data-wow-delay="200ms">
                                    <article className="card card-style-04 border-color-light-black h-100">
                                        <div className="blog-img position-relative">
                                            <img src="img/blog/blog-03.jpg" alt="..."/>
                                            <div className="card-list start-50 translate-middle-x">
                                                <span className="display-31"><i className="ti-calendar me-1 text-white"></i> Apr 03, 2023</span>
                                            </div>
                                        </div>
                                        <div className="card-body p-1-9 pt-2-3">
                                            <h3 className="h4 mb-1-6 border-bottom border-color-light-black pb-1-6"><a href="blog-details.html" >5 energy advancements upset the creating scene.</a></h3>
                                            <a href="blog-details.html" className="text-secondary text-primary-hover fw-bold display-28">Read More</a>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 mt-2-2 wow fadeIn" data-wow-delay="200ms">
                                    <article className="card card-style-04 border-color-light-black h-100">
                                        <div className="blog-img position-relative">
                                            <img src="img/blog/blog-04.jpg" alt="..."/>
                                            <div className="card-list start-50 translate-middle-x">
                                                <span className="display-31"><i className="ti-calendar me-1 text-white"></i> Apr 01, 2023</span>
                                            </div>
                                        </div>
                                        <div className="card-body p-1-9 pt-2-3">
                                            <h3 className="h4 mb-1-6 border-bottom border-color-light-black pb-1-6"><a href="blog-details.html" >Technologies that are changing the future.</a></h3>
                                            <a href="blog-details.html" className="text-secondary text-primary-hover fw-bold display-28">Read More</a>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 mt-2-2 wow fadeIn" data-wow-delay="200ms">
                                    <article className="card card-style-04 border-color-light-black h-100">
                                        <div className="blog-img position-relative">
                                            <img src="img/blog/blog-05.jpg" alt="..."/>
                                            <div className="card-list start-50 translate-middle-x">
                                                <span className="display-31"><i className="ti-calendar me-1 text-white"></i> Mar 29, 2023</span>
                                            </div>
                                        </div>
                                        <div className="card-body p-1-9 pt-2-3">
                                            <h3 className="h4 mb-1-6 border-bottom border-color-light-black pb-1-6"><a href="blog-details.html" >Power transmission and distribution.</a></h3>
                                            <a href="blog-details.html" className="text-secondary text-primary-hover fw-bold display-28">Read More</a>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6 mt-2-2 wow fadeIn" data-wow-delay="200ms">
                                    <article className="card card-style-04 border-color-light-black h-100">
                                        <div className="blog-img position-relative">
                                            <img src="img/blog/blog-06.jpg" alt="..."/>
                                            <div className="card-list start-50 translate-middle-x">
                                                <span className="display-31"><i className="ti-calendar me-1 text-white"></i> Mar 26, 2023</span>
                                            </div>
                                        </div>
                                        <div className="card-body p-1-9 pt-2-3">
                                            <h3 className="h4 mb-1-6 border-bottom border-color-light-black pb-1-6"><a href="blog-details.html" >Ten ingenious ways you can do with wind energy.</a></h3>
                                            <a href="blog-details.html" className="text-secondary text-primary-hover fw-bold display-28">Read More</a>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default index;