import React from 'react';
import Banner from './banner'

const Faq = () => {
    return (
        <div>
        <Banner/>
        <section style={{padding: '60px 0'}}>
          <div className="container-fluid px-lg-6 px-xxl-18 position-relative z-index-9">
            <div className="row">
                <div className="col-xl-8 mx-auto wow fadeIn" data-wow-delay="200ms">
                    <div className="bg-white p-1-6 p-sm-2-9 shadow">
                        <div id="accordion" className="accordion-style">
                            <div className="card mb-3">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">1. What types of solar inverters do you offer?</button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                    <div className="card-body">
                                    <p><span >At Sunsathi Solar, we offer a range of solar inverters, including:</span></p>
                                    <ul>
                                        <li style={{ listStyleType:'disc' }}>
                                            <p><strong><span >Single-Phase Inverters</span></strong><span >: Ideal for residential applications or smaller commercial setups.</span></p>
                                        </li>
                                        <li style={{ listStyleType:'disc' }}>
                                            <p><strong><span >Three-Phase Inverters</span></strong><span >: Suited for larger commercial installations and industrial applications.</span></p>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">2. What is MPPT, and why is it important?</button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                    <div className="card-body">
                                    MPPT stands for Maximum Power Point Tracking. It is a technology used in solar inverters to optimize the amount of power generated by the solar panels. MPPT ensures that the inverter operates at the maximum power point of the solar panels, enhancing efficiency and energy production.
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header" id="headingThree">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">3. What is the difference between single MPPT and dual MPPT inverters?</button>
                                    </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                                    <div className="card-body">
                                    <ul>
                                        <li style={{ listStyleType:'disc', }}>
                                            <p><strong><span >Single MPPT Inverters</span></strong><span >: These inverters have one MPPT channel and are typically used for systems with uniform solar panel orientations. They are cost-effective for simple setups where shading or panel orientation is not a concern.</span></p>
                                        </li>
                                        <li style={{ listStyleType:'disc', }}>
                                            <p><strong><span >Dual MPPT Inverters</span></strong><span >: These inverters have two MPPT channels, allowing for independent optimization of two separate arrays or sections of panels. This is beneficial in installations with varying panel orientations or shading issues, as it maximizes energy harvest from each array.</span></p>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header" id="headingFour">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">4. Which type of inverter is right for my installation?</button>
                                    </h5>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                                    <div className="card-body">
                                    <ul>
                                        <li style={{ listStyleType:'disc', }}>
                                            <p><strong><span >Single-Phase Inverters</span></strong><span >: Best for residential properties or small commercial systems where the load demand is relatively low.</span></p>
                                        </li>
                                        <li style={{ listStyleType:'disc', }}>
                                            <p><strong><span >Three-Phase Inverters</span></strong><span >: Recommended for larger commercial or industrial installations due to their ability to handle higher power loads and provide balanced power across all three phases.</span></p>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header" id="headingFive">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">5. What are the benefits of using Sunsathi Solar inverters?</button>
                                    </h5>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-bs-parent="#accordion">
                                    <div className="card-body">
                                    <p><span >Our inverters offer several benefits, including:</span></p>
                                    <ul>
                                        <li style={{ listStyleType:'disc', }}>
                                            <p><strong><span >High Efficiency</span></strong><span >: Advanced MPPT technology ensures optimal energy conversion.</span></p>
                                        </li>
                                        <li style={{ listStyleType:'disc', }}>
                                            <p><strong><span >Reliability</span></strong><span >: Robust design and high-quality components for long-term performance.</span></p>
                                        </li>
                                        <li style={{ listStyleType:'disc', }}>
                                            <p><strong><span >Versatility</span></strong><span >: Suitable for a wide range of applications from residential to industrial.</span></p>
                                        </li>
                                        <li style={{ listStyleType:'disc', }}>
                                            <p><strong><span >Support and Service</span></strong><span >: Comprehensive customer support and service options.</span></p>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header" id="heading6">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">6. How do I choose between a single-phase and three-phase inverter?</button>
                                    </h5>
                                </div>
                                <div id="collapse6" className="collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                                    <div className="card-body">
                                    <p><span >The choice depends on your specific needs:</span></p>
                                    <ul>
                                        <li style={{ listStyleType:'disc', }}>
                                            <p><strong><span >Single-Phase Inverters</span></strong><span >: Ideal for smaller systems with lower power requirements.</span></p>
                                        </li>
                                        <li style={{ listStyleType:'disc', }}>
                                            <p><strong><span >Three-Phase Inverters</span></strong><span >: Suitable for larger systems where balanced power distribution is required and for higher energy consumption.</span></p>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header" id="heading7">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">7. Are your inverters compatible with all solar panel brands?</button>
                                    </h5>
                                </div>
                                <div id="collapse7" className="collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                                    <div className="card-body">
                                    Yes, Sunsathi Solar inverters are designed to be compatible with a wide range of solar panel brands and types. For optimal performance, we recommend consulting with our technical team to ensure compatibility with your specific solar panel setup.
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header" id="heading8">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">8. What is the warranty period for Sunsathi Solar inverters?</button>
                                    </h5>
                                </div>
                                <div id="collapse8" className="collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                                    <div className="card-body">
                                    We offer a standard warranty period of 8 years on our solar inverters, covering manufacturing defects and performance issues. Extended warranty options are also available.
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header" id="heading9">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">9. How can I get technical support or service for my inverter?</button>
                                    </h5>
                                </div>
                                <div id="collapse9" className="collapse" aria-labelledby="heading9" data-bs-parent="#accordion">
                                    <div className="card-body">
                                    Our dedicated support team is available to assist with any technical issues or service needs. You can reach out to us via phone, email, or through our website's support portal for prompt assistance. For more details visit: www.sunsathisolar.com.
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header" id="heading10">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">10. How do I place an order or request a quote for your inverters?</button>
                                    </h5>
                                </div>
                                <div id="collapse10" className="collapse" aria-labelledby="heading10" data-bs-parent="#accordion">
                                    <div className="card-body">
                                    To place an order or request a quote, please contact us directly via phone, email, or fill out the inquiry form on our website. Our sales team will provide you with all the necessary information and assist you with your purchase.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
        </div>
    );
}

export default Faq;