import React from 'react';
import Banner from './banner'

const Terms = () => {
    return (
        <div>
        <Banner/>
        <section>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-11">
                    <h2><strong><span style={{ fontSize: '17pt' }}>Terms &amp; Conditions of Sunsathi Solar Pvt. Ltd.</span></strong></h2>
                    <h3><strong><span style={{ fontSize: '13pt' }}>Introduction</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>Welcome to Sunsathi Solar Pvt. Ltd. These Terms &amp; Conditions govern your use of our website and the purchase and use of our products and services. By accessing our website or using our services, you agree to comply with these terms. Please read them carefully.</span></p>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Acceptance of Terms</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>By accessing or using any part of the site, you agree to be bound by these Terms &amp; Conditions. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.</span></p>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Changes to Terms</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>Sunsathi Solar Pvt. Ltd. reserves the right to modify these Terms &amp; Conditions at any time. We will notify you of any changes by posting the new Terms &amp; Conditions on our website. You are advised to review these terms periodically for any changes. Your continued use of the service after any modifications indicate your acceptance of the new terms.</span></p>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Products and Services</span></strong></h3>
                    <h4><strong><span style={{ fontSize: '11pt' }}>Product Descriptions</span></strong></h4>
                    <p><span style={{ fontSize: '11pt' }}>We strive to ensure that our product descriptions are as accurate as possible. However, we do not warrant that product descriptions or other content is accurate, complete, reliable, current, or error-free.</span></p>
                    
                    <h4><strong><span style={{ fontSize: '11pt' }}>Orders and Payments</span></strong></h4>
                    <ul>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Order Acceptance:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;All orders placed through our website are subject to acceptance by Sunsathi Solar Pvt. Ltd. We reserve the right to refuse or cancel any order for any reason at any time.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Payment Terms:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;Payment for products and services must be made at the time of purchase. We accept various payment methods, which are detailed on our website.</span></p>
                        </li>
                    </ul>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Shipping and Delivery</span></strong></h3>
                    <ul>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Shipping Policy:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;We aim to ship products in a timely manner. Shipping times may vary based on the product and destination. Any shipping dates provided are estimates only.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Delivery Issues:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;If there are any issues with delivery, such as delays or damages, please contact us immediately to resolve the matter.</span></p>
                        </li>
                    </ul>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Returns and Refunds</span></strong></h3>
                    <ul>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Return Policy:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;Products can be returned within a specified period, provided they are in their original condition and packaging. Detailed return policies are available on our website.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Refunds:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;Refunds will be processed after we receive and inspect the returned products. Refunds will be made using the original payment method.</span></p>
                        </li>
                    </ul>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Warranty</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>Sunsathi Solar Pvt. Ltd. provides a warranty for its products as detailed in the product documentation. The warranty covers manufacturing defects and does not cover damage caused by misuse, accidents, or unauthorized repairs.</span></p>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Limitation of Liability</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>To the fullest extent permitted by law, Sunsathi Solar Pvt. Ltd. shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</span></p>
                    <ul>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><span style={{ fontSize: '11pt' }}>Your use or inability to use the services;</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><span style={{ fontSize: '11pt' }}>Any unauthorized access to or use of our servers and/or any personal information stored therein;</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><span style={{ fontSize: '11pt' }}>Any interruption or cessation of transmission to or from our services;</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><span style={{ fontSize: '11pt' }}>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party;</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><span style={{ fontSize: '11pt' }}>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the services.</span></p>
                        </li>
                    </ul>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Intellectual Property</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>All content, trademarks, and data on this website, including but not limited to text, designs, graphics, and photographs, are the property of Sunsathi Solar Pvt. Ltd. and are protected by applicable intellectual property laws. Unauthorized use of this content is prohibited.</span></p>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Governing Law</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>These Terms &amp; Conditions are governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of India.</span></p>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Contact Us</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>If you have any questions about these Terms &amp; Conditions, please contact us at:</span></p>
                    <p><strong><span style={{ color: '#1f1f1f', backgroundColor: '#ffffff', fontSize: '10.5pt' }}>Address:</span></strong><span style={{ color: '#1f1f1f', backgroundColor: '#ffffff', fontSize: '10.5pt' }}>&nbsp;Plot No 48, Govinda Industrial Park, In Front of Lally Lawn, Walad, MIDC, Amravati, Maharashtra 444607</span><span style={{ fontSize: '11pt' }}>&nbsp;</span></p>
                    <p><strong><span style={{ fontSize: '11pt' }}>Phone Number:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;07066138289&nbsp;</span></p>
                    <p><strong><span style={{ fontSize: '11pt' }}>Email Address:&nbsp;</span></strong><span style={{ fontSize: '11pt' }}>info@sunsathi.com</span></p>
                    
                    <p><br/></p>
                    <hr/>
                    <p><br/></p>
                    <p><span style={{ fontSize: '11pt' }}>By adhering to these Terms &amp; Conditions, Sunsathi Solar Pvt. Ltd. aims to provide a transparent and reliable service to all our customers. Thank you for choosing us as your trusted solar inverter manufacturer.</span></p>
                </div>
            </div>
        </div>
        </section>
        </div>
    );
}

export default Terms;