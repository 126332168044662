import React from 'react';


const Footer = () => {
    return (
        <div>
            <footer className="footer1 position-relative bg-dark" style={{ backgroundImage: "url('img/bg/Footer.png')" }}>
            <div className="container z-index-9 position-relative">
                <div className="row mt-n5 pb-6 pb-lg-7 pb-xxl-8">
                    <div className="col-md-6 col-lg-5 mt-5 wow fadeIn" data-wow-delay="200ms" style={{visibility: 'visible', animationDelay: '200ms', animationName: 'fadeIn'}}>                        
                        <div className="footer-logo mb-1-9">
                            <a href="index.html"><img src="/img/logos/footer-light-logo.png" alt="..."/></a>
                        </div>
                        <ul className="list-unstyled mb-0">
                            <li className="mb-3">
                                <a href="#!" className="text-white text-primary-hover"><i className="far fa-envelope text-secondary display-25 me-4"></i>sales@sunsathisolar.com</a>
                            </li>
                            <li className="mb-3">
                                <a href="#!" className="text-white text-primary-hover"><i className="fas fa-mobile-alt text-secondary display-25 me-4"></i>(+91) 8484838985</a>
                            </li>
                            <li className="text-white">
                                <i className="fas fa-map-marker-alt text-secondary display-25 me-4"></i>Plot No 50, Govinda Industrial Park, Infront of Lally
                                Lawn, Walad, MIDC, Amravati, Maharashtra 444607
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mt-5 wow fadeIn" data-wow-delay="300ms" style={{visibility: 'visible', animationDelay: '200ms', animationName: 'fadeIn'}}>
                        <div className="ps-md-7">
                            <h3 className="text-white h5 mb-1-9">Quick Links</h3>
                            <ul className="footer-link mb-0 list-unstyled">
                                <li className="mb-3"><a href="/">Home</a></li>
                                <li className="mb-3"><a href="/about">About</a></li>
                                <li className="mb-3"><a href="/product">Products</a></li>
                                <li className="mb-3"><a href="/faqs">FAQ</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 mt-5 wow fadeIn" data-wow-delay="400ms" style={{visibility: 'visible', animationDelay: '200ms', animationName: 'fadeIn'}}>
                        <div className="ps-lg-4 ps-xl-7">
                            <h3 className="text-white h5 mb-1-9">Important Links</h3>
                            <ul className="footer-link mb-0 list-unstyled">
                                <li className="mb-3"><a href="/privacy-policy">Privacy Policy</a></li>
                                <li className="mb-3"><a href="/terms-conditions">Terms & Conditions</a></li>
                                <li className="mb-3"><a href="/contact">Contact</a></li>
                                <li className="mb-3"><a href="/datasheet">Datasheet</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-top border-color-light-white z-index-9 position-relative wow fadeIn" data-wow-delay="200ms"  style={{visibility: 'visible', animationDelay: '200ms', animationName: 'fadeIn'}}>
                <div className="container">
                    <div className="row align-items-center py-4">
                        <div className="col-md-6 col-lg-5 text-center text-md-start order-2 order-md-1">
                            <p className="d-inline-block text-white mb-0">© <span className="current-year">2024</span> Powered by <a href="#!" className="text-primary text-secondary-hover">Sunseed Group Of Company</a></p>
                        </div>
                        <div className="col-md-3 col-lg-4 text-center text-md-start order-2 order-md-1">
                            <p className="d-inline-block text-white mb-0">Developed by <a href="http://threeartisans.com/" rel="nofollow" className="text-primary text-secondary-hover">Three Artisans Multiservices</a></p>
                        </div>
                        <div className="col-md-3 col-lg-3 text-center text-md-end mb-3 mb-md-0 order-1 order-md-2">
                            <ul className="social-icon-style1">
                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=61563330522738&mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/sunsathisolar/"><i className="fab fa-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="https://x.com/sunsathisolar?t=c8PZbxVRX0dV4n9NBe7nEQ&s=09"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/sunsathi-solar-pvt-ltd/"><i className="fab fa-linkedin-in"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        </div>
    );
}
export default Footer;