import React from 'react';
import { Link } from 'react-router-dom'


const about_block = () => {
    return (
        <section className="about-style-04">
            <div className="container">
                <div className="row align-items-center mt-n2-6">
                    <div className="col-lg-6 mt-2-6 wow fadeIn" data-wow-delay="200ms">
                        <div className="position-relative">
                            <div className="row align-items-center">
                                <div className="col-5">
                                    <div>
                                        <img src="img/content/about6.png" alt="..."/>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div>
                                        <img src="img/content/about7.png" alt="..."/>
                                    </div>
                                </div>
                            </div>
                            <div className="position-absolute bottom-n5 bottom-sm-5 left-25">
                                <span className="year-box">5+Years of Experience in Renewable Energy</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-2-6 wow fadeIn" data-wow-delay="400ms">
                        <div className="ps-lg-6 ps-xl-12">
                            <div className="section-title-02 mb-1-9">
                                <span className="line-one"></span>
                                <span className="line-two"></span>
                                <h2 className="display-20 font-weight-800 mb-0">Discover the Perfect Solar Solution with Sunsathi Solar Inverter</h2>
                            </div>
                            <p className="font-weight-100" style={{textAlign:'justify'}}>Discover unparalleled efficiency and reliability with the Sunsathi Solar Inverter, your perfect solution for seamless solar energy integration. Maximize your energy savings and contribute to a greener future with our cutting-edge technology.</p>
                            <div className="review-img align-items-center d-flex">
                                {/* <img src="img/avatars/avatar-08.jpg" alt="..." className="rounded-circle w-60px border border-3 border-color-white"/>
                                <img src="img/avatars/avatar-09.jpg" alt="..." className="img-1 rounded-circle w-60px border border-3 border-color-white"/>
                                <img src="img/avatars/avatar-04.jpg" alt="..." className="img-1 rounded-circle w-60px border border-3 border-color-white me-3"/> */}
                                <a href="/about" className="font-weight-800">Learn More <i className="ti-arrow-right ms-2 align-middle text-primary"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default about_block;