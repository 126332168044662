import React from 'react';
import Banner from './banner';
import Form from './form'
import Info from './info'

const Contact = () => {
  return (
    <div>
      <Banner />
      <Form />
      <Info/>
    </div>
  );
};

export default Contact;
