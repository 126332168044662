import React from 'react';
import Banner from './banner';
import Index from './index'

const Certificate = () => {
  return (
    <div>
      <Banner />
      <Index />
    </div>
  );
};

export default Certificate;
