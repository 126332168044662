import React from 'react';
import { Link } from 'react-router-dom'
import Banner from './banner'

const Policy = () => {
    return (
        <div>
        <Banner/>
        <section>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-11">
                    <h3><strong><span style={{ fontSize: '13pt' }}>Introduction</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>At Sunsathi Solar Pvt. Ltd., we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. By accessing or using our services, you agree to the terms of this Privacy Policy.</span></p>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Information We Collect</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>We collect several types of information to provide and improve our services to you:</span></p>
                    
                    <h4><strong><span style={{ fontSize: '11pt' }}>Personal Information</span></strong></h4>
                    <ul>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Contact Information:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;Name, email address, phone number, and mailing address.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Financial Information:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;Payment details such as credit card numbers when you purchase our products.</span></p>
                        </li>
                    </ul>
                    
                    <h4><strong><span style={{ fontSize: '11pt' }}>Non-Personal Information</span></strong></h4>
                    <ul>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Usage Data:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;Information about how you interact with our website, including IP addresses, browser type, pages visited, and time spent on pages.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Cookies:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;Data files that are placed on your device to enhance your user experience.</span></p>
                        </li>
                    </ul>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>How We Use Your Information</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>We use the information we collect in the following ways:</span></p>
                    <ul>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>To Provide Services:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;To process orders, manage accounts, and deliver products.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>To Improve Our Services:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;To understand how our services are used and to enhance user experience.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>To Communicate:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;To send updates, promotional materials, and other information that may be of interest to you.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>To Ensure Security:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;To protect against unauthorized access, fraud, and other security issues.</span></p>
                        </li>
                    </ul>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Sharing Your Information</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>We may share your information with third parties in the following situations:</span></p>
                    <ul>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Service Providers:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;With vendors who perform services on our behalf, such as payment processing and data analysis.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Legal Requirements:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;If required by law, to comply with legal processes or to protect our rights.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Business Transfers:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;In connection with a merger, acquisition, or sale of assets.</span></p>
                        </li>
                    </ul>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Your Choices</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>You have several choices regarding your information:</span></p>
                    <ul>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Access and Update:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;You can access and update your personal information by contacting us.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Opt-Out:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;You can opt out of receiving promotional communications from us by following the unsubscribe instructions in those messages.</span></p>
                        </li>
                        <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                            <p><strong><span style={{ fontSize: '11pt' }}>Cookies:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;You can control cookies through your browser settings.</span></p>
                        </li>
                    </ul>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Security</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>We use a variety of security measures to protect your information from unauthorized access and use. However, no data transmission over the internet can be guaranteed to be 100% secure.</span></p>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Changes to This Privacy Policy</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</span></p>
                    
                    <h3><strong><span style={{ fontSize: '13pt' }}>Contact Us</span></strong></h3>
                    <p><span style={{ fontSize: '11pt' }}>If you have any questions about this Privacy Policy, please contact us at:</span></p>
                    <p><strong><span style={{ fontSize: '11pt' }}>Sunsathi Solar Pvt. Ltd.:&nbsp;</span></strong></p>
                    <p><strong><span style={{ color: '#1f1f1f', backgroundColor: '#ffffff', fontSize: '10.5pt' }}>Address:</span></strong><span style={{ color: '#1f1f1f', backgroundColor: '#ffffff', fontSize: '10.5pt' }}>&nbsp;Plot No 48, Govinda Industrial Park, Infront of Lally Lawn, Walad, MIDC, Amravati, Maharashtra 444607</span><span style={{ fontSize: '11pt' }}>&nbsp;</span></p>
                    <p><strong><span style={{ fontSize: '11pt' }}>Phone Number:</span></strong><span style={{ fontSize: '11pt' }}>&nbsp;(+91) 8484838985&nbsp;</span></p>
                    <p><strong><span style={{ fontSize: '11pt' }}>Email Address:&nbsp;</span></strong><span style={{ fontSize: '11pt' }}>sales@sunsathisolar.com</span></p>
                    <p><br/></p>
                    <p><br/></p>
                </div>
            </div>
        </div>
        </section>
        </div>
    );
}

export default Policy;