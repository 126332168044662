import React from 'react';
import { Link } from 'react-router-dom'


const process_block = () => {
    return (
        <section className="bg-img cover-background" data-overlay-dark="2" style={{ backgroundImage: "url('img/bg/Home-Section-4-Background-1920x650.webp')" }}>
            <div className="container">
                <div className="row mt-n1-9">
                    <div className="col-lg-5 col-xxl-6 mt-1-9 wow fadeIn" data-wow-delay="200ms">
                        <div className="pe-xl-6 pe-xxl-15">
                            <div className="section-title-02 mb-1-9">
                                <span className="line-one"></span>
                                <span className="line-two"></span>
                                <h2 className="display-5 font-weight-800 mb-0 text-white">Why Choose Sunsathi Solar?</h2>
                            </div>
                            <p className="mb-2-6 text-white opacity7">Choosing the right solar provider is essential for maximizing the benefits of solar energy. Sunsathi Solar stands out as a leading choice for several reasons. Here's why you should consider Sunsathi Solar:</p>
                            <a href="/about" className="btn-style1 medium">Learn More</a>
                        </div>
                    </div>
                    <div className="col-lg-7 col-xxl-6 mt-1-9 wow fadeIn" data-wow-delay="400ms">
                        <div>
                            <div className="row mt-n1-9">
                                <div className="col-sm-6 mt-1-9">
                                    <div className="process-style-02 h-100">
                                        <i className="flaticon-010-ecology-2"></i>
                                        <h4 className="mb-0 text-white h5">Advanced Technology</h4>
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-1-9">
                                    <div className="process-style-02 h-100">
                                        <i className="flaticon-008-whale"></i>
                                        <h4 className="mb-0 text-white h5">Reliable and Durable</h4>
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-1-9">
                                    <div className="process-style-02 h-100">
                                        <i className="flaticon-046-green-energy"></i>
                                        <h4 className="mb-0 text-white h5">Eco-Friendly Solutions</h4>
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-1-9">
                                    <div className="process-style-02 h-100">
                                        <i className="flaticon-004-socket"></i>
                                        <h4 className="mb-0 text-white h5">Comprehensive Support</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default process_block;