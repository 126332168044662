import { useParams } from 'react-router-dom';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`https://secure.sunsathisolar.com/api/blog/${slug}`)
      .then((response) => {
        console.log(response);
        setPost(response.data.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [slug]);

  if (error) return <p>Error: {error}</p>;

  return (
    <div>
        {post ? (
        <>
        <section className="page-title-section bg-img cover-background dark-overlay top-position" data-overlay-dark="6" style={{ backgroundImage: 'url(/img/banner/About-Us-Section-1-Banner-1920.webp)' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="display-3">{post.title}</h1>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/blogs">Blogs</a></li>
                            <li><a href="#!">{post.title}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="line-animated">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="p-1-9 p-sm-2-3 bg-white bottom-0 left d-inline-block position-absolute z-index-3"></div>
            <div className="position-absolute shape-1 d-none d-sm-block"></div>
            <div className="px-6 px-sm-9 py-3 py-sm-4 bg-white bottom-0 right d-inline-block position-absolute z-index-3"></div>
        </section>
        <section style={{padding:'60px 0'}}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb-5 mb-lg-0">
                        <div class="row">
                            <div class="col-lg-12 mb-6">
                                <article class="card border-color-light-black">
                                    <div class="card-body p-1-6 p-sm-1-9">
                                        <ul class="list-unstyled mb-3 wow fadeIn" data-wow-delay="100ms">
                                            <li class="d-inline-block me-3"><a href="#!" class="display-31"><i class="ti-calendar me-1 text-primary"></i> {post.date}</a></li>
                                        </ul>
                                        <h3 class="mb-3 border-bottom pb-3 wow fadeIn" data-wow-delay="100ms">{post.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: post.description }} />
                                    </div>
                                </article>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
        ) : (
            <section style={{padding:'60px 0'}}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 mb-5 mb-lg-0">
                        <h1>Post not found</h1>
                        </div>
                    </div>
                </div>
            </section>

        )}
    </div>
  );
};

export default BlogPost;
