import React from 'react';
import Banner from './banner';
import Block from './index'

const Blog = () => {
  return (
    <div>
      <Banner />
      <Block/>
    </div>
  );
};

export default Blog;
