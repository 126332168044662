import React from 'react';

const Banner = () => {
    return (
        <section className="bg-img cover-background parallax dark-overlay" data-overlay-dark="0" style={{ backgroundImage: 'url(img/banner/Home-Section-1-Banner-1920x900.webp)', paddingTop:'70px', paddingBottom:'160px' }}>
            <div className="container pb-1-6 pb-sm-2-6 pb-md-0 pt-sm-6 pt-md-0 mt-n2-9">
                <div className="row" style={{ display: 'inline-block'}}>
                    <div className="col-lg-6 col-xl-12 text-center py-5">
                        <div className="section-title-02 mb-2-2">
                            <span className="line-one center"></span>
                            <span className="line-two center"></span>
                            <h2>Introducing <span style={{color:'#008000'}}>Sunsathi Solar Inverter</span></h2>
                            <h1 className="font-weight-800 mb-0 text-white text-uppercase"><span style={{color:'#2e266d'}}>Bright Ideas for a </span> <span style={{color:'#008000'}}>Green Planet</span></h1>
                        </div>
                        <div>
                            <a href="/about" className="btn-style1 primary m-1">Read More</a>
                            <a href="/contact" className="btn-style1 m-1">Contact Us</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12"></div>
                </div>
            </div>
        </section>
    );
}

export default Banner;

{/* <section
    className="jarallax p-0 top-position1 full-screen video-banner dark-overlay"
    data-overlay-dark="0"
    style={{ backgroundImage: 'url(img/banner/Home-Section-1-Banner-1600x900.webp)' }}
    data-jarallax
    data-speed="0.8"
    >
    
    <div className="container pt-5 pb-2 py-sm-8 py-md-0 position-relative z-index-9">
        <div className="row align-items-center min-vh-100 ">
            <div className="col-lg-1 col-xs-12"></div>
            <div className="col-lg-6 col-xs-12">
                <div className="main-title text-center">
                    <div className="section-title-02 mb-2-2">
                        <span className="line-one center"></span>
                        <span className="line-two center"></span>
                        <h2>Introducing <span style={{color:'#008000'}}>Sunsathi Solar Inverter</span></h2>
                        <h1 className="font-weight-800 mb-0 text-white text-uppercase"><span style={{color:'#2e266d'}}>Bright Ideas for a </span> <span style={{color:'#008000'}}>Green Planet</span></h1>
                    </div>
                    <div>
                        <a href="/about" className="btn-style1 primary m-1">Read More</a>
                        <a href="/contact" className="btn-style1 m-1">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}