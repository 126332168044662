import React from 'react';
import { Link } from 'react-router-dom'
import Banner from './banner';

const Gallery = () => {
    return (
        <div>
        <Banner />
        <section>
            <div className="container">
                <div className="row">
                    <div className="filtering col-sm-12 text-center">
                        <span data-filter='*' className="active">View All</span>
                        <span data-filter='.energy'>Energy</span>
                        <span data-filter='.commercial'>Commercial</span>
                        <span data-filter='.wind'>Wind</span>
                        <span data-filter='.solar'>Solar</span>
                    </div>
                </div>
                <div className="row portfolio-gallery-isotope mt-n4">
                    <div className="col-md-6 col-xl-3 mt-4 items wind" data-src="img/portfolio/07.jpg" data-sub-html="<h4 className='text-white'>Wind Turbine #01</h4><p>Windmill</p>">
                        <div className="portfolio-style-02">
                            <div className="portfolio-img">
                                <img src="img/portfolio/07.jpg" alt="..."/>
                                <i className="ti-search portfolio-icon"></i>
                            </div>
                            <div className="portfolio-text">
                                <span className="text-primary font-weight-600">Windmill</span>
                                <h3 className="h5 mb-0"><a href="portfolio-details.html" className="portfolio-link">Wind Turbine</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 mt-4 items energy" data-src="img/portfolio/08.jpg" data-sub-html="<h4 className='text-white'>Energy Station #02</h4><p>Energy</p>">
                        <div className="portfolio-style-02">
                            <div className="portfolio-img">
                                <img src="img/portfolio/08.jpg" alt="..."/>
                                <i className="ti-search portfolio-icon"></i>
                            </div>
                            <div className="portfolio-text">
                                <span className="text-primary font-weight-600">Energy</span>
                                <h3 className="h5 mb-0"><a href="portfolio-details.html" className="portfolio-link">Energy Station</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 mt-4 items energy" data-src="img/portfolio/09.jpg" data-sub-html="<h4 className='text-white'>Renewable Energy Station #03</h4><p>Energy</p>">
                        <div className="portfolio-style-02">
                            <div className="portfolio-img">
                                <img src="img/portfolio/09.jpg" alt="..."/>
                                <i className="ti-search portfolio-icon"></i>
                            </div>
                            <div className="portfolio-text">
                                <span className="text-primary font-weight-600">Energy</span>
                                <h3 className="h5 mb-0"><a href="portfolio-details.html" className="portfolio-link">Renewable Energy Station</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 mt-4 items commercial" data-src="img/portfolio/10.jpg" data-sub-html="<h4 className='text-white'>Easy Installation #04</h4><p>Commercial</p>">
                        <div className="portfolio-style-02">
                            <div className="portfolio-img">
                                <img src="img/portfolio/10.jpg" alt="..."/>
                                <i className="ti-search portfolio-icon"></i>
                            </div>
                            <div className="portfolio-text">
                                <span className="text-primary font-weight-600">Commercial</span>
                                <h3 className="h5 mb-0"><a href="portfolio-details.html" className="portfolio-link">Easy Installation</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 mt-4 items solar" data-src="img/portfolio/11.jpg" data-sub-html="<h4 className='text-white'>Solar Field #05</h4><p>Solar power</p>">
                        <div className="portfolio-style-02">
                            <div className="portfolio-img">
                                <img src="img/portfolio/11.jpg" alt="..."/>
                                <i className="ti-search portfolio-icon"></i>
                            </div>
                            <div className="portfolio-text">
                                <span className="text-primary font-weight-600">Solar power</span>
                                <h3 className="h5 mb-0"><a href="portfolio-details.html" className="portfolio-link">Solar Field</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 mt-4 items solar" data-src="img/portfolio/12.jpg" data-sub-html="<h4 className='text-white'>Robust Experience #06</h4><p>Solar Panels</p>">
                        <div className="portfolio-style-02">
                            <div className="portfolio-img">
                                <img src="img/portfolio/12.jpg" alt="..."/>
                                <i className="ti-search portfolio-icon"></i>
                            </div>
                            <div className="portfolio-text">
                                <span className="text-primary font-weight-600">Solar Panels</span>
                                <h3 className="h5 mb-0"><a href="portfolio-details.html" className="portfolio-link">Robust Experience</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 mt-4 items wind" data-src="img/portfolio/17.jpg" data-sub-html="<h4 className='text-white'>End-To-End Services #07</h4><p>Windmill</p>">
                        <div className="portfolio-style-02">
                            <div className="portfolio-img">
                                <img src="img/portfolio/17.jpg" alt="..."/>
                                <i className="ti-search portfolio-icon"></i>
                            </div>
                            <div className="portfolio-text">
                                <span className="text-primary font-weight-600">Windmill</span>
                                <h3 className="h5 mb-0"><a href="portfolio-details.html" className="portfolio-link">End-To-End Services</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 mt-4 items commercial" data-src="img/portfolio/18.jpg" data-sub-html="<h4 className='text-white'>Project Planing #08</h4><p>Commercial</p>">
                        <div className="portfolio-style-02">
                            <div className="portfolio-img">
                                <img src="img/portfolio/18.jpg" alt="..."/>
                                <i className="ti-search portfolio-icon"></i>
                            </div>
                            <div className="portfolio-text">
                                <span className="text-primary font-weight-600">Commercial</span>
                                <h3 className="h5 mb-0"><a href="portfolio-details.html" className="portfolio-link">Project Planing</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    );
}

export default Gallery;