import React from 'react';


const testimonial_block = () => {
    return (
        <section className="bg-img cover-background dark-overlay" data-overlay-dark="8" style={{ backgroundImage: "url('img/banner/About-Us-Section-5-What-Our-Clients-Says.webp')" }}>
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-lg-6 col-xxl-5">
                        <div className="bg-white-opacity-light p-1-9 p-sm-2-9">
                            <img src="img/icons/quote.png" className="mb-4" alt="..."/>
                            <h2 className="mb-4 text-white">What Our Client Say</h2>
                            <div className="testimonial-carousel3 owl-carousel owl-theme">
                                <div>
                                    <p className="mb-4 display-28 text-white">"The Sunsathi Solar Inverter is efficient and has reduced my bills significantly. Installation was easy, and service was great. A smart, eco-friendly investment!"</p>
                                    <h6 className="mb-0 text-white">Aarav Sharma</h6>
                                </div>
                                <div>
                                    <p className="mb-4 display-28 text-white">"Sunsathi Solar Inverter works perfectly with my panels. It's reliable, efficient, and eco-friendly. Highly recommend for solar solutions!"</p>
                                    <h6 className="mb-0 text-white">Priya Patel</h6>
                                </div>
                                <div>
                                    <p className="mb-4 display-28 text-white">"Sunsathi Solar Inverter has cut my grid reliance and is durable. Professional installation and great for sustainability."</p>
                                    <h6 className="mb-0 text-white">Rajesh Kumar</h6>
                                </div>
                                <div>
                                    <p className="mb-4 display-28 text-white">"The Sunsathi Solar Inverter boosts energy efficiency and the real-time monitoring is useful. A hassle-free transition to clean energy."</p>
                                    <h6 className="mb-0 text-white">Neha Desai</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-2-6 bg-white bottom-0 left d-inline-block position-absolute z-index-3"></div>
            <div className="px-7 py-4 bg-white top-0 right d-inline-block position-absolute z-index-3"></div>
            <div className="p-1-6 bg-white d-inline-block position-absolute testimonial-shape z-index-3 d-none d-lg-block"></div>
        </section>
    );
}

export default testimonial_block;